<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="travelsModal"
    >   
        <CForm id="travel_form" method="POST" enctype="multipart/form-data">
          <CRow>
            <CCol md="8">
              <CSelect
                  label="Colegio"
                  id="school_id"
                  name="school_id"
                  :value.sync="$v.form.school_id.$model"
                  :options="schoolsOptions"
                  :disabled="this.form.id > 0"
                />
            </CCol>
            <CCol md="4">
              <CSelect
                  label="Periodo"
                  id="period"
                  name="period"
                  :value.sync="$v.form.period.$model"
                  :options="periodOptions"
                />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CInput
                label="Nombre:"
                id="name"
                name="name"
                :lazy="false"
                :value.sync="$v.form.name.$model"
                :isValid="checkIfValid('name')"
                placeholder=""
                autocomplete="given-name"
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
            </CCol>
            <CCol md="3">
              <CInput
                label="Destino:"
                id="destination"
                name="destination"
                :lazy="false"
                :value.sync="$v.form.destination.$model"
                :isValid="checkIfValid('destination')"
                placeholder=""
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
              />
            </CCol>
            <CCol md="3">
              <CSelect
                label="Tipo:"
                id="type"
                name="type"
                :value.sync="$v.form.type.$model"
                :options="[{label: ' Nacional', value: 'national'}, {label: 'Estados Unidos', value: 'usa'}, {label: 'Internacional', value: 'international'}]"
              />
            </CCol>
          </CRow>
          <CRow>                        
            <CCol md="6">
              <div role="group" class="form-group">
                <label>Fecha Salida:</label>
                <v-date-picker
                  id="start_date"
                  name="start_date"
                  class="date-picker"
                  v-model="$v.form.start_date.$model"
                  locale="es-MX" size="sm"
                />
              </div>
            </CCol>
            <CCol md="6">
              <div role="group" class="form-group">
                <label>Fecha Regreso:</label>
                <v-date-picker
                  id="end_date"
                  name="end_date"
                  class="date-picker"
                  v-model="$v.form.end_date.$model"
                  locale="es-MX" size="sm"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
              <CCol md="6">
                <CInput
                  label="Responsable:"
                  id="contact_name"
                  name="contact_name"
                  :lazy="false"
                  :value.sync="$v.form.contact_name.$model"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Celular / Whatsapp:"
                  id="contact_phone"
                  name="contact_phone"
                  :lazy="false"
                  :value.sync="$v.form.contact_phone.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Link de Presentación:"
                  id="presentation_link"
                  name="presentation_link"
                  :lazy="false"
                  :value.sync="$v.form.presentation_link.$model"
                  placeholder="http://"
                />
              </CCol>
              <CCol md="6">
                <CInput
                  label="Link de Seguro:"
                  id="insurance_link"
                  name="insurance_link"
                  :lazy="false"
                  :value.sync="$v.form.insurance_link.$model"
                  placeholder="http://"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <div style="padding-bottom: 10px;">Mensaje de Itinerario (1):<br /></div>
                <vue-editor v-model="form.itinerary_message1" :editor-toolbar="customToolbar" />
                <br />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                Plan de Pagos:{{payments}}
                <table style="width: 100%">
                  <tr v-for="(payment, i) in form.payments" :key="'payments-'+i" v-if="payment.visible">
                    <td class="small_text" style="border: 0px solid; padding: 5px;">
                      <CInput
                        placeholder="Descripción del pago"
                        v-model="payment.description"
                      />
                    </td>
                    <td class="small_text" style="border: 0px solid; padding: 5px; width: 100px;">
                      <CSelect
                        @change="changeBankAccount($event, payment)"
                        :options="bankAccountsOptions"
                        v-model="payment.bank_account_base"
                      />
                    </td>
                    <td class="small_text" style="border: 0px solid; padding: 5px; width: 90px;">
                      <CInput class="input_centered"
                        placeholder="Monto"
                        v-model="payment.amount"
                        style="font-size: 10px;"
                      />
                    </td>
                    <td class="small_text" v-show="$v.form.type.$model != 'national'" style="border: 0px solid; padding: 5px; width: 90px;">
                      <CSelect                        
                        @change="changeCurrency($event, payment)"
                        :options="currencyOptions"
                        v-model="payment.currency"
                      />
                    </td>
                    <td class="small_text" v-show="$v.form.type.$model != 'national'" style="border: 0px solid; padding: 5px; width: 60px !important;">
                      <CInput class="input_centered"
                        placeholder="TC"
                        v-model="payment.exchange_rate"
                         style="width: 65px !important;"
                      />
                    </td>
                    <td class="small_text" style="border: 0px solid; padding: 5px; width: 110px;">
                      <div role="group" class="form-group">
                        <v-date-picker
                          class="date-picker date100"
                          locale="es-MX" size="sm"
                          style="width: 90px !important; font-size: 12px;"
                          v-model="payment.date"
                        />
                      </div>
                    </td>
                    <td class="text-center" style="border: 0px solid; padding: 5px; width: 50px;">
                      <CButton color="info" style="height: 27px; padding:0px 6px 0 7px; margin-left: 5px;" size="sm" @click="payment = deletePaymentModal(payment);" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}"><CIcon name="cil-trash"/></CButton>
                    </td>
                  </tr>
                </table>
                <br />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <div style="padding-bottom: 10px;">Mensaje de Itinerario (2):<br /></div>
                <vue-editor v-model="form.itinerary_message2" :editor-toolbar="customToolbar" />
                <br />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="image"
                    id="image"
                    label="Imagen de Portada:"
                    :lazy="false"
                    :value.sync="$v.form.image.$model"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CInput
                  label="Link de Grupo de Whatsapp:"
                  id="whatsapp_link"
                  name="whatsapp_link"
                  :lazy="false"
                  :value.sync="$v.form.whatsapp_link.$model"
                  placeholder="http://"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">{{form.id > 0 ? 'Guardar' : 'Registrar Viaje'}}</CButton>
        </template>

        <DeleteModal ref="deleteModal" @delete="deletePaymentProcess"></DeleteModal>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import { VueEditor } from "vue2-editor";
import travels from '../../services/travels';
import schools from '../../services/schools';
import bank_accounts from '../../services/bank_accounts';
import payments from '../../services/payments';
import store from '../../store'

import DeleteModal from '../global/DeleteModal.vue'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'TravelModal',
  components: {
    'v-date-picker': DatePicker,
    VueEditor,
    DeleteModal
  },
  props: {

  },
  data () {
    return {
      travelsModal: false,
      title: "Nuevo Viaje",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      schools: [],
      schoolsOptions: [],
      bank_accounts: [],
      bankAccountsOptions: [],
      currencyOptions: [{"value": "USD", "label": "USD"}],
      exchange_rate: 18.52,
      submitted: false,
      content: "<h1>Some initial content</h1>",
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ],
      periodOptions: []
    }
  },
  mounted: async function() {
    let start = new moment().subtract(2,"years");
    let date = [];
    for (let index = 1; index <= 3; index++) {
      start = start.add(1,"years");
      let index_date = start.format("YYYY")
      date.push({"label": index_date + "-"+ (index_date*1 + 1),"value": index_date})          
    }
    this.periodOptions =  date;
    let response = await schools.list();

    if(response.type == "success"){
      this.schools = response.data;
    }

    this.schoolsOptions = await this.$parseSelectOptionsOrdered(this.schools, "value", "text");

    let response2 = await bank_accounts.list();

    if(response2.type == "success"){
      this.bank_accounts = response2.data;
    }

    this.bankAccountsOptions = await this.$parseSelectOptions(this.bank_accounts, "value", "text");

    this.form.school_id = this.schoolsOptions[0].value;
  },
  methods: {
    changeBankAccount(event, payment){
      payment.bank_account_id = event.target.value;
    },
    changeCurrency(event, payment){
      payment.currency = event.target.value;
    },
    storeModal () {
      this.travelsModal = true;
      this.color = "info";
      this.title = 'Nuevo Viaje';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          school_id: '0',
          type: 'national',
          destination: '',
          image: '',
          start_date: '',
          end_date: '',
          period: new moment().format("YYYY"),
          welcome_message: '',
          whatsapp_link: '',
          payments: [
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            }
          ]
      }
    },
    duplicateModal (item) {
      this.travelsModal = true;
      this.color = "success";
      this.title = 'Duplicar Viaje';
      this.isEdit = false;
      this.submitted = false;

      let start_date = item.start_date != null ? moment(item.start_date, "YYYY-MM-DD") : moment().add(1, 'months');
      let end_date = item.end_date != null ? moment(item.end_date, "YYYY-MM-DD") : moment().add(1, 'months').add(10, 'days');

      this.form = {
          id: 0,
          name: item.name,
          school_id: item.school_id,
          parent_id: item.id,
          type: item.type,
          destination: item.destination,
          contact_name: item.contact_name,
          contact_phone: item.contact_phone,
          image: '',
          period: new moment().format("YYYY"),
          start_date: start_date.toDate(),
          end_date: end_date.toDate(),
          welcome_message: item.welcome,
          presentation_link: item.presentation,
          itinerary_message1: item.itinerary1,
          itinerary_message2: item.itinerary2,
          insurance_link: item.insurance,
          whatsapp_link: item.whatsapp_group_link,
          payments: [
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            }
          ]
      };

      let i = 0;
      item.payments.forEach(payment => {
        this.form.payments[i].description = payment.name;
        this.form.payments[i].amount = payment.amount;
        this.form.payments[i].currency = payment.currency;

        if(this.form.type != "national"){
          this.form.payments[i].exchange_rate = payment.exchange_rate;
          this.exchange_rate = payment.exchange_rate;
        }

        this.form.payments[i].bank_account_id = payment.bank_account_id != undefined ? payment.bank_account_id+"" : "0";
        this.form.payments[i].bank_account_base = payment.bank_account_id != undefined ? payment.bank_account_id+"" : "0";

        let deadline = moment(payment.deadline, "YYYY-MM-DD");

        this.form.payments[i].date = deadline.toDate();

        i++;
      });

      while(i < 5){
        this.form.payments[i].exchange_rate = this.exchange_rate;
        i++;
      }
    },
    updateModal (item) {
      this.travelsModal = true;
      this.color = "warning";
      this.title = 'Editar Viaje';
      this.isEdit = true;
      this.submitted = false;

      let start_date = item.start_date != null ? moment(item.start_date, "YYYY-MM-DD") : moment().add(1, 'months');
      let end_date = item.end_date != null ? moment(item.end_date, "YYYY-MM-DD") : moment().add(1, 'months').add(10, 'days');

      this.form = {
          id: item.id,
          name: item.name,
          school_id: item.school_id,
          type: item.type,
          destination: item.destination,
          contact_name: item.contact_name,
          contact_phone: item.contact_phone,
          image: '',
          period: item.period,
          start_date: start_date.toDate(),
          end_date: end_date.toDate(),
          welcome_messge: item.welcome,
          presentation_link: item.presentation,
          itinerary_message1: item.itinerary1,
          itinerary_message2: item.itinerary2,
          insurance_link: item.insurance,
          whatsapp_link: item.whatsapp_group_link,
          payments: [
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            }
          ]
      };

      let i = 0;
      item.payments.forEach(payment => {
        if(this.form.payments[i] != undefined){
          this.form.payments[i].id = payment.id;
          this.form.payments[i].description = payment.name;
          this.form.payments[i].amount = payment.amount;
          this.form.payments[i].currency = payment.currency;

          this.form.payments[i].id = payment.id;

          if(this.form.type != "national"){
            this.form.payments[i].exchange_rate = payment.exchange_rate;
            this.exchange_rate = payment.exchange_rate;
          }

          this.form.payments[i].bank_account_id = payment.bank_account_id != undefined ? payment.bank_account_id+"" : "0";
          this.form.payments[i].bank_account_base = payment.bank_account_id != undefined ? payment.bank_account_id+"" : "0";

          let deadline = moment(payment.deadline, "YYYY-MM-DD");

          this.form.payments[i].date = deadline.toDate();
        }
        
        i++;
      });

      while(i < 5){
        this.form.payments[i].exchange_rate = this.exchange_rate;
        i++;
      }
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("travel_form");
        const formData = new FormData(form);

        formData.append("id", this.form.id);
        formData.append("parent_id", this.form.parent_id != undefined ? this.form.parent_id : 0);

        formData.append("itinerary_message1", this.form.itinerary_message1);
        formData.append("itinerary_message2", this.form.itinerary_message2);

        let i = 0;
        this.form.payments.forEach(payment => {
          if(payment.id > 0){
            formData.append("payment_id_"+i, payment.id);
          }
          else{
            formData.append("payment_id_"+i, 0);
          }
          formData.append("payment_description_"+i, payment.description);
          formData.append("payment_amount_"+i, payment.amount);

          if(this.form.type == 'national'){
            formData.append("payment_currency_"+i, "MXN");
            formData.append("payment_exchange_"+i, 1);
          }
          else{
            formData.append("payment_currency_"+i, "USD");
            formData.append("payment_exchange_"+i, payment.exchange_rate);
          }

          formData.append("bank_account_id_"+i, payment.bank_account_id);

          let date = new moment(payment.date);
          formData.append("payment_deadline_"+i, date.format("YYYY-MM-DD"));

          i++;
        });

        if(this.form.start_date){
          let start_date = new moment(this.form.start_date);
          formData.append("start_date", start_date.format("YYYY-MM-DD"));
        }
        
        if(this.form.end_date){
          let end_date = new moment(this.form.end_date);
          formData.append("end_date", end_date.format("YYYY-MM-DD"));
        }
        
        if(this.form.id > 0){
          formData.append("id", this.form.id);
          
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    deletePaymentModal (item) {
      if(item.id > 0){
        this.$refs.deleteModal.show("Eliminar Pago", item.id, item.description);
      }
      else{
        item.bank_account_base = "0";
        item.bank_account_id = "0";
        item.description = "";
        item.amount = "";
        item.currency = "";
        item.exchange_rate = this.exchange_rate;
        item.date = false;
        item.id = 0
        item.visible = false;

        return item;
      }
    },
    async deletePaymentProcess(id) {
      let response = await payments.deleteTravelPayment({id: id});
      
      if(response.type == "success"){
        this.closeModal();

        this.$emit("refresh");
      }

      this.showToast(response.type, response.message);
    },
    closeModal () {
      this.travelsModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      let form = {
          id: 0,
          name: '',
          school_id: 0,
          type: 'national',
          destination: '',
          image: '',
          perido: new moment().format("YYYY"),
          start_date: '',
          end_date: '',
          welcome_message: '',
          itinerary_message1: '',
          itinerary_message2: '',
          payments: [
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              currency: "",
              exchange_rate: this.exchange_rate,
              date: false,
              id: 0,
              visible: true
            }
          ]
      };

      return form;
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      school_id: {},
      type: {
        required
      },
      destination: {
        required
      },
      image: {
        
      },
      start_date: {

      },
      period: {

      },
      end_date: {

      },
      contact_name: {

      },
      contact_phone: {
        
      },
      welcome_message: {

      },
      presentation_link: {
        
      },
      itinerary_message1: {

      },
      itinerary_message2: {

      },
      insurance_link: {

      },
      whatsapp_link: {

      }
    }
  },
}
</script>
<style>
  .small_text input{
    font-size: 12px !important;
  }

  .small_text select{
    font-size: 12px !important;
  }
</style>  